import Button from "./button/button";
// import "./presentation.css";
import loco1 from "./presentation/presentationIMG/lateral1.webp";
import loco2 from "./presentation/presentationIMG/lateral2.webp";
import informa2 from "./presentation/presentationIMG/fest2025.webp";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const novedades = [
  {
    icono: "🔥",
    titulo: "Fit Experience Fest Infantil",
    texto:
      "¡Los más pequeños también vivirán la experiencia! Un espacio diseñado para que disfruten del deporte y la actividad física de una manera divertida y emocionante.",
  },
  {
    icono: "💪",
    titulo: "Competición Crosstraining",
    texto:
      "Llega el desafío definitivo para los más atrevidos. Pon a prueba tu resistencia y fuerza en una de las competiciones más exigentes del mundo del fitness.",
  },
  {
    icono: "🌍",
    titulo: "Atletas Internacionales",
    texto:
      "Entrena y aprende de los mejores. Este año, contamos con invitados de renombre que compartirán sus conocimientos, técnicas y motivación contigo.",
  },
  {
    icono: "🎤",
    titulo: "Cierre con concierto y artistas sorpresa",
    texto:
      "Porque después de darlo todo, mereces una celebración épica. Disfruta de una noche inolvidable con música en vivo y un show espectacular.",
  },
];

const Presentation2025 = () => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (inView) setShow(true);
  }, [inView]);
  return (
    <section
      className="presentation__event presentation__reverse novedad2025"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${informa2})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="presentation__text columnizq">
        <div
          className="buttons__container buttonmovil"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Button text={"Venta de entradas próximamente"} />
        </div>
        <h2
          className="presentation__title"
          style={{
            color: "hsl(50, 70%, 50%)",
            textAlign: "center",
            width: "100%",
            margin: "35px 0px 5px 0px",
          }}
        >
          ¡Vuelve el evento fitness más esperado del año!
        </h2>
        <h3 className="presentation__slogan">
          ¡Prepárate para la experiencia fitness más grande del año!
        </h3>
        <p>
          El Fit Experience Fest 2025 regresa más grande, más intenso y más
          inspirador que nunca. Si en 2024 marcamos la diferencia, este año
          llevaremos la energía al siguiente nivel.
        </p>
        <p>
          Imagina un espacio donde la motivación se respira en el aire, donde
          cada entrenamiento, cada ponencia y cada desafío te acercan más a tu
          mejor versión. Un evento diseñado para superar tus límites, conectar
          con expertos y compartir la pasión por el fitness con cientos de
          personas que, como tú, buscan una vida más activa y saludable.
        </p>
        <p>
          Desde clases con entrenadores de élite hasta retos en vivo,
          exhibiciones, atracciones, charlas motivacionales y lo último en
          nutrición y bienestar, el Fit Experience Fest 2025 será el punto de
          encuentro para quienes no se conforman con lo ordinario.
        </p>
        <p>
          Este año, el cambio eres tú. ¿Estás listo para sentir la adrenalina,
          la superación y la comunidad en su máxima expresión?
        </p>
        <h3 className="presentation__slogan novedades">Novedades 2025:</h3>
        <ul ref={ref}>
          {novedades.map((item, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, y: 30 }}
              animate={show ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              style={{
                marginBottom: "20px",
                listStyle: "none",
                fontSize: "1.4rem",
                lineHeight: "1.6",
              }}
            >
              {item.icono} <strong>{item.titulo}</strong> – {item.texto}
            </motion.li>
          ))}
        </ul>
        <h1
          className="presentation__title"
          style={{ textAlign: "center", width: "100%" }}
        >
          FIT Experience FEST <span className="presentation__year">2025</span>
        </h1>

        <div
          className="buttons__container"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Button text={"Venta de entradas próximamente"} />
        </div>
      </div>
      <div className="imgvertical__container">
        {[loco1, loco2].map((img, index) => (
          <motion.img
            key={index}
            src={img}
            alt={`Imagen ${index + 1}`}
            className="imgvertical__img lazy"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.6,
              delay: index * 0.3,
              ease: "easeOut",
            }}
            viewport={{ once: true, amount: 0.3 }}
          />
        ))}
      </div>
    </section>
  );
};

export default Presentation2025;
