import video from "./fitexperiencefest-2025.m4v";
export default function Page() {
  return (
    <video
      className="videopromo"
      src={video}
      controls
      loop
      muted
      autoPlay
      style={{ maxWidth: "100%" }}
    />
  );
}
