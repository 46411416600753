import React from "react";
import "./Home.css";
import Banner from "../components/banner/Banner";
import Header from "../components/header/Header";
import Button from "../components/button/button";
import Presentation from "../components/presentation/presentation";
import Sponsors from "../components/sponsors/sponsors";
import Youtube from "../components/youtube/youtube";
import Information from "../components/information/information";
import Timeline from "../components/timeline/timeline";
import Hoteles from "../components/hoteles/hoteles";
import Footer from "../components/footer/footer";
import InfoDev from "../components/footer/icons-socials/infoDEV/infoDEV";
import Novedad25 from "../components/2025.jsx"

const Home = () => {
  return (
    <section className="Home">
      <Banner />
      <Novedad25 />
      {/* <Presentation /> */}
      <Youtube />
      <Sponsors />
      <Information />
      <Timeline />
    </section>
  );
};

export default Home;
